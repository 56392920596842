import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import BodyText from "lib/@cms/components/shared/BodyText";
import Divider from "lib/@cms/components/primitive/Divider";

function VisaCreditCard() {
  return (
    <Page pathname="visa-credit-card">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradient}
            />
            <Divider className="tw-my-2" />

            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>

              {data.TextsLists?.map((item, index) => {
                const isListDotten = item.list?.length >= 1 && index === 1;

                return (
                  <React.Fragment key={`TextsList-${index}`}>
                    <BodyText
                      data={item}
                      variant={
                        isListDotten
                          ? BodyText.variant.DECORATION_DOTTEN
                          : BodyText.variant.DECORATION_NONE
                      }
                    />
                    <Divider className="tw-my-7" />
                  </React.Fragment>
                );
              })}
            </div>

            <Divider className="tw-my-16 sm:tw-my-24" />
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default VisaCreditCard;
